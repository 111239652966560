import styles from "./Splash.module.scss";
import svg from "../assets/images/Shopaholics - Seller.svg";
function Splash() {
  return (
    <div className={styles.splash}>
      <div className={styles.main}>
        <div>
          <h1>Hail 2</h1>
          <h1>Thrift</h1>
        </div>
        <div>Life's too short to pay full price</div>
      </div>
      <div className={styles.logo}>
        <img className={styles.svg} src={svg} />
        <ul>
          <li className={styles.bubble}></li>
          <li className={styles.bubble}></li>
          <li className={styles.bubble}></li>
          <li className={styles.bubble}></li>
          <li className={styles.bubble}></li>
        </ul>
      </div>
    </div>
  );
}

export default Splash;
