import styles from "./Tiny.module.scss";
function Splash() {
  return (
    <div className={styles.tiny}>
      <p>Buy Stuff</p>
    </div>
  );
}

export default Splash;
