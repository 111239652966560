import "./App.css";
import Header from "./components/Header";
import Contact from "./components/Contact";
import About from "./components/About";
import Splash from "./components/Splash";
import Tiny from "./components/Tiny";
function App() {
  return (
    <div className="App">
      <Header />
      <Tiny />
      <Splash />
      <About />
      <Contact />
    </div>
  );
}

export default App;
