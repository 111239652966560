import React from "react";
import styles from "./Header.module.scss";
function Header() {
  return (
    <div className={styles.header}>
      <div>
        <p>neat logo</p>
      </div>
      <div>
        <p>Inventory</p>
      </div>
      <div>
        <p>About</p>
      </div>
      <div>
        <p>Contact</p>
      </div>
    </div>
  );
}

export default Header;
