import React from "react";
import style from "./About.module.scss";

export default function About() {
  return (
    <div class={style.about}>
      <div class={style.section}>
        <h2>Who Be We?</h2>
        <p>Some</p>
      </div>
      <div class="section">
        <h2>What Be Doin?</h2>
        <p>Lobor</p>
      </div>
      <div class="section">
        <h2>How Me Buy?</h2>
        <p>bulum sed arcu.</p>
      </div>
    </div>
  );
}
